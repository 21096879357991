<div [formGroup]="form">
    <app-searchable-autocomplete #brandSearch="objectSearchAutoComplete"
                                 [objectType]="'BrandPartner'"></app-searchable-autocomplete>

    <div style="text-align: center; width: 100%; padding: 20px">
        <div>
            <img [src]="imageUrl" style="max-width: 300px; height: auto"/>
        </div>
        <div style="padding:10px" *ngIf="allowChangeImage">
            <button mat-raised-button (click)="uploadFileInputRef?.openPicker()"
                    [disabled]="isUploadingImage">Upload product image
            </button>
            <upload-file-input #uploadFileInput (onLocalFilesAdded)="handleLocalFileAdded($event)"></upload-file-input>
            
        </div>
    </div>

    <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" autocomplete="off"/>
    </mat-form-field>

    <mat-form-field>
        <mat-label>UPC</mat-label>
        <input matInput formControlName="upc" autocomplete="off"/>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Article ID</mat-label>
        <input matInput formControlName="articleId" autocomplete="off"/>
    </mat-form-field>

    <app-autocomplete-search-field
            placeholder="Brand"
            [searchObjectType]="'BrandPartner'"
            [value]="selectedBrandPartner"
            selectionMode="single"
            [disabled]="!brandPartnerEditable"
            (valueChanged)="onBrandPartnerSelected($event)"></app-autocomplete-search-field>

    <app-autocomplete-search-field
            placeholder="Retailer"
            [searchObjectType]="'Retailer'"
            [value]="selectedRetailers"
            selectionMode="multi"
            [disabled]="!retailerEditable"
            (valueChanged)="onRetailerSelected($event)"></app-autocomplete-search-field>

</div>