<cdk-virtual-scroll-viewport #virtualScrollEl style="flex: 1; min-height: 100px; height: 100%" [itemSize]="itemSize">
    <mat-card *cdkVirtualFor="let schedule of schedulesDataProvider.loadedData; let index = index"
              [attr.data-row-id]="schedule.getId()"
              [attr.data-row-index]="index" class="mat-elevation-z3 m-16">

        <div fxLayout="row">
            <div fxFlex="0 0 550px">
                <span class="mat-card-title">{{ schedule.name }}</span>
            </div>
            <div fxFlex="0 0 200px" fxLayout="row" fxLayoutAlign="end" style="margin-left: auto">
                <button mat-icon-button title="Edit" (click)="editSchedule(schedule)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button title="Delete" color="warn" (click)="deleteSchedule(schedule)"
                        *ngIf="canDeleteSchedule(schedule)">
                    <mat-icon>remove_circle</mat-icon>
                </button>
            </div>
        </div>
        
        <looma-grid [dataSource]="getPromoPeriodsDataSource(schedule)" [scrollContent]="false" rowHeight="auto">
            <ng-template rowExpansionTemplate let-data="data">
                <looma-grid [dataSource]="getSegmentsDataSource(data)" [scrollContent]="false"
                            [rowActions]="segmentActions">
                </looma-grid>
            </ng-template>

            <ng-template gridColumn="promo_period_status" let-promoPeriod="data">
                <mat-icon [title]="promoPeriod.statusName"
                          [style.color]="promoPeriod.isActive ? 'green' : promoPeriod.isSoonStarting ? 'blue' : 'rgba(0,0,0,0.1)'"
                          style="transform: scale(0.4,0.4);vertical-align: middle" title="warning"
                          aria-hidden="true">brightness_1
                </mat-icon>
            </ng-template>

            <ng-template rowActions="4" let-promoPeriod="data" let-dataSource="dataSource">
                <button mat-icon-button title="Assign device slots" (click)="assignDeviceSlots(promoPeriod)"
                        *ngIf="canAssignDeviceSlots(promoPeriod)">
                    <mat-icon>assignment</mat-icon>
                </button>
                <button mat-icon-button title="Manage Playlists"
                        (click)="showPeriodPlaylists(dataSource.promoSchedule, promoPeriod)">
                    <mat-icon>playlist_play</mat-icon>
                </button>
                <button mat-icon-button title="Edit" (click)="editPeriod(dataSource.promoSchedule, promoPeriod)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button title="Delete" color="warn"
                        *ngIf="canDeletePeriod(dataSource.promoSchedule, promoPeriod)"
                        (click)="deletePeriod(dataSource.promoSchedule, promoPeriod)">
                    <mat-icon color="warn">remove_circle</mat-icon>
                </button>
            </ng-template>

            <ng-template emptyDataContent>
                <div style=" height:50px; line-height: 50px; text-align: center">
                    No Data Available
                </div>
            </ng-template>

            <ng-template gridHeading></ng-template>

            <ng-template gridColumn="programs" let-promoPeriod="data" let-dataSource="dataSource">
                <mat-chip-list>
                    <mat-chip [matMenuTriggerFor]="programMenu"
                              *ngFor="let prog of getUsedPromoPrograms(promoPeriod)">
                        <span>{{ prog.name }}</span>
                        <mat-menu #programMenu="matMenu">
                            <button mat-menu-item (click)="showEditSegmentDialogBox(prog, promoPeriod)"
                                    *ngIf="canAddSegments(promoPeriod)">Add new segment
                            </button>
                            <button mat-menu-item
                                    (click)="showCopySegmentsDialogBox(dataSource.promoSchedule, prog, promoPeriod)"
                                    *ngIf="canCopySegments(promoPeriod)">Copy segments
                            </button>
                            <button mat-menu-item
                                    (click)="showProgramOverview(dataSource.promoSchedule, prog, promoPeriod)">
                                Campaigns
                            </button>
                            
                            <ng-container [ngSwitch]="prog.kioskDeviceApp.id">
                                <ng-container *ngSwitchCase="SurveyApp.id">
                                    <button mat-menu-item
                                            (click)="showProgramSurveys(dataSource.promoSchedule, prog, promoPeriod)">
                                        Manage Survey
                                    </button>
                                    <button mat-menu-item
                                            (click)="showHomeScreenPlaylist(prog, promoPeriod)">
                                        Homescreen Playlist
                                    </button>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <button mat-menu-item
                                            (click)="showProgramPlaylists(dataSource.promoSchedule, prog, promoPeriod)">
                                        Campaign Content
                                    </button>
                                </ng-container>
                            </ng-container>

                            <button mat-menu-item
                                    (click)="showSubmissions(prog, promoPeriod)">
                                Brand submissions
                            </button>
                        </mat-menu>
                    </mat-chip>
                </mat-chip-list>
            </ng-template>
        </looma-grid>

        <mat-card-actions>
            <button mat-button color="warn" (click)="addPromoPeriod(schedule)">Add Promo Period</button>
        </mat-card-actions>

    </mat-card>
</cdk-virtual-scroll-viewport>

<ng-template #tplHeader>
    <button mat-raised-button (click)="createNewSchedule()">New</button>
</ng-template>

<ng-template #tplEditPromoPeriod let-editor="editor" let-promoPeriod="editor.promoPeriod">
    <form [formGroup]="editor.form" style="background: white; width:100%; height:100%; position: relative"
          class="mat-elevation-z3" autocomplete="off" autofocus>
        <div data-proxy-column="name">
            <input autofocus matInput placeholder="Name" formControlName="name">
        </div>
        <div data-proxy-column="start_date">
            <input class="date-input" readonly matInput
                   [matDatepicker]="rangeStartPicker"
                   placeholder="Start Date"
                   [min]="editor.minStartDate"
                   (dateChange)="editor?.onDateChanged('start_date', $event)"
                   formControlName="start_date">
            <mat-datepicker-toggle matSuffix [for]="rangeStartPicker"></mat-datepicker-toggle>
            <mat-datepicker #rangeStartPicker></mat-datepicker>
        </div>
        <div data-proxy-column="end_date">
            <input class="date-input"
                   readonly matInput
                   [min]="editor.minEndDate"
                   [matDatepicker]="rangeEndPicker" placeholder="End Date"
                   (dateChange)="editor?.onDateChanged('end_date', $event)" formControlName="end_date">
            <mat-datepicker-toggle matSuffix [for]="rangeEndPicker"></mat-datepicker-toggle>
            <mat-datepicker #rangeEndPicker></mat-datepicker>
        </div>

        <div data-proxy-column="programs">
            <app-autocomplete-search-field placeholder="Programs"
                                           [disabled]="!editor.promoPeriod.isEditable"
                                           [searchCriteria]="editor.programSearchCriterias"
                                           searchObjectType="PromoProgram" selectionMode="multi"
                                           (valueChanged)="editor.onProgramsSelected($event)"
                                           [value]="editor.promoPrograms"></app-autocomplete-search-field>
        </div>

        <div data-proxy-column="duration">
            <span>{{ editor.dayDuration }}</span>
        </div>

        <div style="position: absolute;right:0; height:100%;" data-proxy-column>
            <button mat-icon-button (click)="editor.dismiss()">
                <mat-icon style="color: red">cancel</mat-icon>
            </button>
            <button mat-icon-button [disabled]="editor.isBusy || !editor.isValid()" class="submit_button"
                    [class.spinner]="editor.isBusy" (click)="editor.save()">
                <mat-icon>check_circle_outline</mat-icon>
            </button>
        </div>
    </form>
</ng-template>