import {BaseModel} from '@looma/shared/models/base_model';
import {BrandPartner} from '@looma/shared/models/brand_partner'
import {Retailer} from "@looma/shared/models/retailer";

export class BrandProduct extends BaseModel {

    public static MiscBrandProductId = -1

    id: string;
    name: string;
    brand_partner: BrandPartner;
    retailers: Retailer[];
    upc_code: string;
    article_id: string;
    image_url: string;
    thumb_url: string;
    updatedAt: string;
    isArchived: boolean;

    public static getMiscBrandProduct(brandPartner: BrandPartner): BrandProduct {
        const p = new BrandProduct();
        p.assign({
            id: BrandProduct.MiscBrandProductId,
            name: "Misc brand products",
            brandPartner: brandPartner,
        })
        return p;
    }

    assign(obj: any): this {
        super.assign(obj);
        this.assignTypedObject(obj, 'brand_partner', BrandPartner);
        this.assignTypedArray(obj, 'retailers', Retailer);
        return this;
    }

    getDisplayName(): string {
        return this.name;
    }

    isNewRecord(): boolean {
        return !this.id
    }
}

export interface ProductInput {
    id?: string;
    name?: string;
    brandPartnerId?: number;
    retailerIds?: number[];
    upcCode?: string;
    articleId?: string;
    imageFirebaseKey?: string;
    isArchived?: boolean;
}

